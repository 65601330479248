.sliderNews-itemNews-overlay {
    height: 245px;
    margin: 0 auto;
}

.sliderNews-itemNews {
    width: 262px;
    /* height: 231px; */
    margin: 0 auto;
}
.sliderNews-itemNews-img {
    width: 261px;
    height: 144px;
    margin-bottom: 10px;
}
.sliderNews-itemNews-img img {
    margin: 0 auto;
    width: 260px;
    height: 144px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.sliderNews-itemNews-discr {
    text-align: center;
}
.sliderNews-itemNews-discr p {
    padding: 4px;
    padding-top: 12px;
}

.primaryNews-item-discr {
    margin-bottom: 10px;
    padding-right: 8px;
    /* min-height: 150px; */
    max-height: 150px;
    overflow-y: auto;
    white-space: pre-wrap;
    text-align: justify;
}
.primaryNews-item-discr::-webkit-scrollbar {
    width: 5px;
}
.primaryNews-item-discr::-webkit-scrollbar-thumb {
    background: #586290;
    border-radius: 10px;
}

@media (min-width: 1200px) {
    .sliderNews-itemNews {
        width: 350px;
    }
    .sliderNews-itemNews-img {
        width: 350px;
        height: 194px;
        margin-bottom: 10px;
    }
    .sliderNews-itemNews-img img {
        width: 350px;
        height: 194px;
    }
}

@media (min-width: 1920px) {
    .sliderNews-itemNews-overlay {
        height: 305px;
    }
    .sliderNews-itemNews {
        width: 407px;
    }
    .sliderNews-itemNews-img {
        width: 407px;
        height: 226px;
        margin-bottom: 10px;
    }
    .sliderNews-itemNews-img img {
        width: 407px;
        height: 226px;
    }
    .sliderNews-itemNews-discr p {
        padding: 8px;
        padding-top: 22px;
    }
}
