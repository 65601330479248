.questions-contentQuestions {
    width: 100%;
    border: 1px solid #586290;
    border-radius: 10px;
    margin-bottom: 20px;
}
.contentQuestions-header {
    padding: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.contentQuestions__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    width: 259px;
}
.contentQuestions__btn {
    background-image: url(../../../../../images/btn_top.svg);
    width: 21px;
    height: 20px;
    background-color: transparent;
    border: none;
}
.contentQuestions-answer {
    padding: 14px;
}
.contentQuestions__discr::before {
    content: "";
    display: block;
    border-top: 1px solid #586290;
    position: relative;
    top: -14px;
}
@media (min-width: 768px) {
    .contentQuestions__title {
        width: 670px;
    }
}
@media (min-width: 992px) {
    .contentQuestions-header {
        padding: 18px;
    }
    .contentQuestions-answer {
        padding: 18px;
    }
    .contentQuestions__title {
        width: 890px;
    }
    .contentQuestions__discr::before {
        top: -18px;
    }
}
@media (min-width: 1200px) {
    .contentQuestions-header {
        padding: 20px;
    }
    .contentQuestions-answer {
        padding: 20px;
    }
    .contentQuestions__title {
        width: 1050px;
    }
    .contentQuestions__discr::before {
        top: -20px;
    }
}
@media (min-width: 1920px) {
    .contentQuestions-header {
        padding: 23px;
    }
    .contentQuestions-answer {
        padding: 23px;
    }
    .contentQuestions__title {
        width: 1200px;
    }
    .contentQuestions__btn {
        background-image: url(../../../../../images/btn_top_xl.svg);
        width: 29px;
        height: 28px;
    }
    .contentQuestions__discr::before {
        top: -23px;
    }
}
