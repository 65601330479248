.footer {
  background: linear-gradient(83.96deg, #586290 45.35%, #727ba5 99.32%);
  /* height: 1381px; */
}

.footer-overlay {
  margin: 0 auto;
  width: 286px;
  padding: 36px 0 8px;
  /* height: 100%; */
}

.footer-content {
  position: relative;
  margin-bottom: 36px;
}

.footer-content-block {
  margin-bottom: 40px;
}

.footer-content-block:last-of-type {
  margin-bottom: 0;
}

.footer-content-block-title {
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 8px;
}

.footer-content-block-item {
  color: #ffffff;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
}

.footer-content-block-item:last-of-type {
  margin-bottom: 0;
}

.footer-content-block-item-overlay {
  display: flex;
}

.footer-content-block-item-overlay svg {
  margin-right: 12px;
  margin-bottom: 8px;
}

.footer-content-block-item-svg {
  margin-right: 12px;
  margin-bottom: 8px;
  width: 24px;
  height: 24px;
}

.footer-svg-telegram {
  background-image: url(../../images/telegram_icon.svg);
}
.footer-svg-viber {
  background-image: url(../../images/viber_icon.svg);
}
.footer-svg-instagram {
  background-image: url(../../images/instagram_icon.svg);
}

/* .footer-content-block-item-overlay svg:last-of-type {
    margin-bottom: 0;
} */

.footer-content-block-item-overlay p {
  padding-top: 2px;
  margin-bottom: 0;
}

.footer-content-block-item-overlay a {
  padding-top: 3px;
  margin-bottom: 0;
  cursor: pointer;
}

.footer-links {
  display: flex;
  /* flex-wrap: nowrap; */
  flex-flow: column wrap;
}

.footer-links-block:first-of-type {
  order: 2;
}

.footer-links-block:last-of-type {
  order: 1;
  margin-bottom: 36px;
}

.footer-links-block-item {
  color: #ffffff;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 6px;
  cursor: pointer;
}
.footer-links-block-item a {
  color: #ffffff;
}

.footer-links-block-item:last-of-type {
  margin-bottom: 0;
  font-weight: 600;
}

.footer-links-block-item-rules {
  color: #ffffff;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.footer-content-blockApp-link-appStore {
  background-image: url(../../../src/images/app_store.png);
  margin-bottom: 15px !important;
}
.footer-content-blockApp-link-googlePlay {
  background-image: url(../../../src/images/google_play.png);
}
.footer-content-blockApp-link {
  display: block;
  width: 202px;
  height: 60px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-size: 0;
}

.footer-content-block__support-link {
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 41px;
  background: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 5px;
  color: #586290;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  outline: none;
  border: none;
  cursor: pointer;
  text-align: center;
  line-height: 41px;
}

@media (min-width: 768px) {
  .footer-overlay {
    width: 720px;
  }

  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0;
  }

  .footer-content-block:last-of-type {
    position: relative;
    top: -80px;
  }

  .footer-links {
    display: flex;
    flex-wrap: nowrap;
    flex-flow: nowrap;
    justify-content: space-between;
    align-items: flex-end;
  }

  .footer-links-block:first-of-type {
    order: 1;
  }

  .footer-links-block:last-of-type {
    order: 2;
    margin-bottom: 0;
  }

  .footer-links-block-item {
    text-align: right;
  }
  .footer-content-blockApp {
    position: absolute;
    left: 180px;
    bottom: 30px;
  }
  .footer-content-block__support-link {
    padding: 0 10px;
    position: relative;
    left: 480px;
  }
}
@media (min-width: 992px) {
  .footer-overlay {
    /* width: 960px; */
  }
}
@media (min-width: 1200px) {
  .footer {
    /* height: 440px; */
  }
  .footer-overlay {
    width: 1140px;
  }
  .footer-content {
    margin-bottom: 24px;
  }
  .footer-content-block:last-of-type {
    position: relative;
    top: 0;
  }
  .footer-content-block-title {
    font-size: 20px;
    line-height: 24px;
  }
  .footer-content-block-item {
    font-size: 14px;
    line-height: 16px;
  }
  .footer-links-block {
    display: flex;
  }
  .footer-links-block-item {
    margin-bottom: 0;
    margin-right: 20px;
  }
  .footer-links-block-item:last-of-type {
    margin-right: 0;
  }
  .footer-content-block__support-link {
    left: 0;
  }
  .footer-content-blockApp {
    left: 938px;
    bottom: 40px;
  }
}
@media (min-width: 1920px) {
  .footer {
    /* height: 440px; */
    background: linear-gradient(91.21deg, #586290 34.34%, #727ba5 109.14%);
  }

  .footer-overlay {
    width: 1280px;
  }
  .footer-content-block-title {
    font-size: 24px;
    line-height: 29px;
  }
  .footer-content-block-item {
    font-size: 16px;
    line-height: 19px;
  }
  .footer-content-blockApp {
    left: 1078px;
  }
}
