.header {
  margin: 0 auto;
  height: 263px;
  background-image: url(../../../images/background_header.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  color: #ffffff;
  /* font-family: Proxima Nova; */
}

.header-overlay {
  position: relative;
  /* display: flex;
    justify-content: space-between; */
  padding: 16px;
  width: 360px;
  height: 100%;
  margin: 0 auto;
}

.header-menu-overlay {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.logo img {
  width: 45px;
  height: 45px;
}

.header-title {
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  width: auto;
  margin-bottom: 8px;
}
.header-title__span {
  font-size: 13px;
}

.header-discription {
  font-style: italic;
  /* font-weight: 600; */
  font-size: 18px;
  line-height: 22px;
  text-align: right;
}
.header-discription span {
  display: block;
}
.header-recom {
  position: absolute;
  width: 328px;
  padding: 8px;
  /* height: 50px; */
  background-color: #fff;
  margin: 0 auto;
  color: #000000;
  bottom: 20px;
}
.header-recom-discr p {
  font-weight: 600;
  font-size: 10.5px;
  line-height: 12px;
  text-align: right;
}
.header-recom-discr__span {
  display: block;
}
.header-recom-discr__italic {
  font-weight: normal;
  font-style: italic;
  display: block;
  font-size: 8.5px;
  /* line-height: 15px; */
}
.header-recom-img {
  position: absolute;
  top: 2.5px;
  left: 4px;
}
.header-recom-img img {
  width: 132px;
  height: 48px;
}

/* .header-recom-img img {
    width: 120px;
    height: 44px;
} */

@media (min-width: 768px) {
  .header {
    background-image: url(../../../images/background_header_768.webp);
    height: 230px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .header-overlay {
    width: 720px;
  }

  .header-menu-overlay {
    margin-bottom: 28px;
  }

  .header-title {
    margin-bottom: 7px;
  }
  .header-title__span {
    font-size: 13px;
  }

  .header-discription {
    /* padding-left: 350px; */
  }
  .header-discription span {
    display: none;
  }
  /* .header-img {
        top: 50%;
        left: 50%;
        transform: translate(-50%, 10%);
    } */
  .header-recom {
    width: 685px;
    padding: 8px 20px;
    left: 19px;
  }
  .header-recom-discr__span {
    display: none;
  }
  .header-recom-img {
    position: absolute;
    top: -0.5px;
    left: 11px;
  }
  .header-recom-img img {
    width: 120px;
    height: 44px;
  }
  .header-recom-discr p {
    font-size: 12px;
    line-height: 14px;
  }
  .header-recom-discr__italic {
    font-size: 10px;
    line-height: 12px;
  }
}

@media (min-width: 992px) {
  .header {
    background-image: url(../../../images/background_header_1920.webp);
    height: 270px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .header-overlay {
    width: 960px;
  }

  .logo img {
    width: 62px;
    height: 62px;
  }

  /* .header-img {
        left: 600px;
        top: 60px;
        transform: translate(0, 0);
    } */
  .header-title {
    margin-bottom: 10px;
  }
  .header-title__span {
    font-size: 14px;
  }
  .header-recom {
    width: 924px;
    padding: 15px 25px;
    left: 17px;
    bottom: 25px;
  }
  .header-recom-discr__italic {
    display: inline;
  }
  .header-recom-discr p {
    font-size: 14px;
    line-height: 17px;
  }
  .header-recom-discr__italic {
    font-size: 12px;
  }
  .header-recom-img {
    top: 1.5px;
  }
}
@media (min-width: 1200px) {
  .header {
    background-image: url(../../../images/background_header_1200.webp);
    height: 310px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .header-overlay {
    width: 1140px;
  }

  .header-menu-overlay {
    margin-bottom: 28px;
  }

  .logo img {
    width: 70px;
    height: 70px;
  }
  .header-title {
    width: auto;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 10px;
  }

  .header-discription {
    font-size: 22px;
    line-height: 26px;
    /* padding-left: 450px; */
  }

  /* .header-img {
        left: 600px;
        top: 30px;
    }

    .header-img img {
        width: 541px;
        height: 477px;
    } */
  .header-recom {
    width: 1106px;
    left: 17px;
    bottom: 35px;
  }
  .header-recom-discr p {
    font-size: 16px;
    line-height: 19px;
  }
  .header-recom-discr__italic {
    font-size: 14px;
  }
  .header-recom-img {
    /* top: 2px; */
  }
  .header-recom-img img {
    width: 132px;
    height: 48px;
  }
}

@media (min-width: 1920px) {
  .header {
    background-image: url(../../../images/background_header_1920.webp);

    height: 390px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .header-overlay {
    width: 1900px;
    /* width: 100%; */
    padding: 40px 310px 0;
  }

  .header-menu-overlay {
    margin-bottom: 30px;
  }

  .logo {
    position: relative;
    left: 0;
    top: -10px;
  }

  .logo img {
    width: 100px;
    height: 100px;
  }

  /* .header-img {
        left: 1122px;
        top: 134px;
    } */

  .header-title {
    width: auto;
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 10px;
  }
  .header-title__span {
    font-size: 21px;
    line-height: 25px;
  }

  .header-discription {
    font-size: 26px;
    line-height: 32px;
    padding: 0;
  }
  .header-recom {
    width: 1283px;
    padding: 20px 30px;
    left: 309px;
    bottom: 37px;
  }
  .header-recom-img {
    top: 8px;
  }
  .header-recom-img img {
    width: 148px;
    height: 54px;
  }
  .header-recom-discr p {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -1px;
  }
  .header-recom-discr__italic {
    font-size: 20px;
  }
}

@media (max-width: 359px) {
  .header {
    width: 100%;
    background-size: cover;
  }
  .header-overlay {
    width: 320px;
  }
  .header-title {
    font-size: 18px;
    width: 300px;
    margin-bottom: 3px;
  }
  .header-title__span {
    font-size: 12px;
  }
  .header-recom {
    width: 288px;
  }
  .header-recom-discr p {
    font-size: 9px;
  }
  .header-recom-img img {
    width: 120px;
    height: 44px;
  }
  .header-recom-discr__italic {
    font-size: 7px;
  }
  .header-recom-img {
    top: 5.2px;
  }
}
