.slick-dots li button:before {
    content: "" !important;
    width: 11.81px !important;
    height: 11.81px !important;
    opacity: 1 !important;
    border: 1px solid #586290;
    border-radius: 50px;
}
.slick-dots li.slick-active button:before {
    color: #586290 !important;
    background: #586290;
}
/* .sliderEvents {
    width: 320px;
    margin: 0 auto;
} */
.sliderNews {
    width: 320px;
    margin: 0 auto;
}
.sliderReviews {
    width: 320px;
    margin: 0 auto;
}
.sliderArrow-btnPrev {
    background-image: url(../../../../images/btn_prev.svg);
    width: 20px;
    height: 21px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: -10px;
}
.sliderArrow-btnNext {
    background-image: url(../../../../images/btn_next.svg);
    width: 20px;
    height: 21px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    right: -10px;
}
.newsArrow {
    top: 65px;
}
.reviewsArrow {
    top: 140px;
}
@media (min-width: 768px) {
    .sliderNews {
        width: 700px;
    }
    .sliderReviews {
        width: 700px;
    }
}
@media (min-width: 992px) {
    .sliderNews {
        width: 940px;
    }
    .sliderReviews {
        width: 940px;
    }
}
@media (min-width: 1200px) {
    .sliderNews {
        width: 1120px;
    }
    .sliderReviews {
        width: 1120px;
    }
    .reviewsArrow {
        top: 170px;
    }
}

@media (min-width: 1920px) {
    .sliderNews {
        width: 1260px;
    }
    .sliderReviews {
        width: 1260px;
    }
    .sliderArrow-btnPrev {
        background-image: url(../../../../images/btn_prev_xl.svg);
        width: 29px;
        height: 29px;
    }
    .sliderArrow-btnNext {
        background-image: url(../../../../images/btn_next_xl.svg);
        width: 29px;
        height: 29px;
    }
    .newsArrow {
        top: 125px;
    }
    .reviewsArrow {
        top: 230px;
    }
}
