.sliderReviews-itemReviews-overlay {
    width: 291px;
    height: 350px;
    margin: 0 auto;
}
.sliderReviews-itemReviews {
    width: 291px;
    height: 335px;
    box-shadow: 0px 4px 10px rgba(96, 106, 150, 0.48);
    border-radius: 10px;
    position: relative;
}
.itemReviews-img {
    color: #ffffff;
    text-align: center;
    width: 291px;
    height: 149px;
    background: #6c75a0;
    box-shadow: 0px 4px 10px rgba(96, 106, 150, 0.48);
    border-radius: 10px;
    padding: 0 10px;
}
.itemReviews-img-avatar {
    padding-top: 12px;
    padding-bottom: 8px;
}
.itemReviews-img-avatar img {
    margin: 0 auto;
    width: 61px;
    height: 61px;
}
.itemReviews-img-title {
    margin-bottom: 9px;
}
.itemReviews-img-discr {
    font-size: 11px;
}
.itemReviews-review {
    padding: 20px 11px 10px;
    text-align: center;
}
.itemReviews-review-discr {
    margin-bottom: 8px;
}
.itemReviews-review img {
    width: 110px;
    height: 21px;
    margin: 0 auto;
    position: absolute;
    bottom: 10px;
    left: 90.5px;
}
@media (min-width: 1200px) {
    .sliderReviews-itemReviews-overlay {
        width: 320px;
        height: 410px;
    }
    .sliderReviews-itemReviews {
        width: 320px;
        height: 395px;
    }
    .itemReviews-img {
        width: 320px;
        height: 164px;
        padding: 0 20px;
    }
    .itemReviews-img-avatar {
        padding-top: 16px;
        padding-bottom: 16px;
    }
    .itemReviews-review img {
        left: 105px;
    }
}

@media (min-width: 1920px) {
    .sliderReviews-itemReviews-overlay {
        width: 379px;
        height: 460px;
    }
    .sliderReviews-itemReviews {
        width: 379px;
        height: 445px;
    }
    .itemReviews-img {
        width: 379px;
        height: 222px;
    }
    .itemReviews-img-avatar {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .itemReviews-img-avatar img {
        width: 81px;
        height: 81px;
    }
    .itemReviews-img-title {
        margin-bottom: 16px;
    }
    .itemReviews-img-discr {
        font-size: 14px;
    }
    .itemReviews-review {
        padding: 25px 15px 10px;
    }
    .itemReviews-review-discr {
        margin-bottom: 25px;
    }
    .itemReviews-review img {
        width: 160px;
        height: 30px;
    }
}
