.menu {
  position: relative;
  right: 0;
}

.menu-navigation {
  display: none;
}

.menu-burger {
  position: relative;
  right: 0;
  top: -6px;
  background: #ffffff;
  border: 1px solid #586290;
  /* width: 21px;
    height: 10px; */
  width: 25px;
  height: 18px;
  /* background: transparent; */
  background-image: url(../../../images/burger.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.menu-navigation-drop-overlay {
  position: relative;
}

.menu-navigation-down-overlay {
  position: absolute;
  width: 250px;
}

.menu-navigation-item-drop {
  margin-bottom: 12px;
  cursor: pointer;
}

.menu-navigation-item-down {
  margin-bottom: 8px;
}

.menu-navigation-drop-span {
  margin-left: 4px;
  display: inline-block;
  width: 22px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 12px;
  cursor: pointer;
}

.drop-span__drop {
  background-image: url(../../../images/dropdown_down.png);
}

.drop-span__down {
  background-image: url(../../../images/dropdown.png);
}

@media (min-width: 768px) {
  .menu-burger {
    background-color: transparent;
    background-image: url(../../../images/burger_xl.svg);
    width: 42px;
    height: 20px;
    top: 0;
  }
}

@media (min-width: 992px) {
  .menu-navigation {
    display: flex;
  }

  .menu-navigation-item {
    margin-right: 12px;
    margin-bottom: 0;
  }

  .menu-navigation-item:first-of-type {
    border: 1px solid #ffffff;
    padding: 5px;
    position: relative;
    top: -7px;
    height: 30px;
    /* letter-spacing: 2px; */
  }

  /* .menu-navigation-item:last-of-type {
    border: 1px solid #ffffff;
    padding: 5px;
    position: relative;
    top: -7px;
    height: 30px;
    margin-right: 0;
  } */

  /* .menu-navigation-item a {
        color: #ffffff;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
    } */

  .menu-navigation-item-link {
    color: #ffffff;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
  }

  .menu-burger {
    display: none;
  }

  .navlink-selected {
    border-bottom: 1px solid #ffffff;
  }
}

@media (min-width: 1200px) {
  .menu {
    right: 0;
  }

  /* .menu-navigation-item a {
        letter-spacing: 0.1px;
        font-size: 14px;
        line-height: 18px;
    } */

  .menu-navigation-item-link {
    letter-spacing: 0.1px;
    font-size: 14px;
    line-height: 16px;
  }
}

@media (min-width: 1920px) {

  /* .menu-navigation-item a {
        font-size: 16px;
        line-height: 19px;
    } */

  .menu-navigation-item-link {
    font-size: 16px;
    line-height: 19px;
  }

  .navlink-selected {
    border-bottom: 1.2px solid #ffffff;
  }
}

.absolute_icon {
  position: absolute;
  width: 143px;
  height: 53px !important;
  top: 75px !important;
  left: 500px;
  padding: 0;
  border: none !important;
}

.absolute_icon_textConteiner{
  display: flex;
  flex-direction: row;
  justify-content: left;
  font-size: 16px;
  font-weight: 600;
  position: fixed;
  right: 0;
  top: 170px;
}


/* .absolute_icon:hover {
  transform: translateY(0px) scale(1.05);
} */

@media (max-width: 992px) {
  .absolute_icon{
    display: none;
  }
}

@media (min-width: 992px) {
  .absolute_icon_textConteiner{
    top: 115px;
  }
}

@media (min-width: 1920px) {
  .absolute_icon_textConteiner{
    top: 170px;
  }
}
