.submenu {
  /* display: none; */
}

.submenu-navigation {
  display: block;
  overflow-y: auto;
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background: linear-gradient(91.21deg, #586290 34.34%, #727ba5 109.14%);
  /* background-image: url(../../../images/mobile-bg.webp); */
  /* background-color: #ffffff; */
  padding: 26px;
}

.submenu-navigation-item {
  margin-bottom: 22px;
  text-align: right;
}

.submenu-navigation-item:last-of-type {
  margin-bottom: 0;
}

.submenu-navigation-item-link {
  color: #ffffff;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}

.closeSubmenu {
  /* // position: absolute; */
  position: fixed;
  top: 5px;
  right: 240px;
  width: 55px;
  height: 55px;
  cursor: pointer;
  z-index: 20;
}

.closeSubmenu:before,
.closeSubmenu:after {
  content: '';
  position: absolute;
  top: 21px;
  left: 10px;
  width: 26px;
  height: 4px;
  background: #ffffff;
}

.closeSubmenu:before {
  webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.closeSubmenu:after {
  webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.submenu-navigation-drop-overlay {
  position: relative;
}

.submenu-navigation-down-overlay {
  /* position: absolute; */
  /* width: 250px; */
}

.submenu-navigation-item-drop {
  margin-bottom: 22px;
  cursor: pointer;
}
.submenu-navigation-item-down {
  margin-bottom: 22px;
  /* font-style: italic; */
  font-weight: 100;
  /* font-size: 17px; */
}

.submenu-navigation-drop-span {
  margin-left: 4px;
  display: inline-block;
  width: 22px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 12px;
  cursor: pointer;
}

.drop-span__drop {
  background-image: url(../../../images/dropdown_down.png);
}
.drop-span__down {
  background-image: url(../../../images/dropdown.png);
}
