.questions-titleQuestions {
    width: 100%;
    padding: 14px;
    border: 1px solid #586290;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
}
.titleQuestions__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    width: 259px;
}
.titleQuestions__btn {
    background-image: url(../../../../../images/btn_bottom.svg);
    width: 21px;
    height: 20px;
    background-color: transparent;
    border: none;
}
@media (min-width: 768px) {
    .titleQuestions__title {
        width: 670px;
    }
}
@media (min-width: 992px) {
    .questions-titleQuestions {
        padding: 18px;
    }
    .titleQuestions__title {
        width: 890px;
    }
}

@media (min-width: 1200px) {
    .questions-titleQuestions {
        padding: 20px;
    }
    .titleQuestions__title {
        width: 1050px;
    }
}

@media (min-width: 1920px) {
    .questions-titleQuestions {
        padding: 23px;
    }
    .titleQuestions__title {
        width: 1200px;
    }
    .titleQuestions__btn {
        background-image: url(../../../../../images/btn_bottom_xl.svg);
        width: 29px;
        height: 28px;
    }
}
