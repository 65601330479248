html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
ul,
p {
  margin: 0;
}

a {
  display: block;
  text-decoration: none;
}

picture {
  display: block;
}

ul {
  list-style: none;
  padding: 0;
}

body {
  margin: 0;
  font-family: "Manrope", sans-serif;
  outline: none;
}

button {
  display: block;
  outline: none;
  cursor: pointer;
}

input {
  outline: none;
}

textarea {
  outline: none;
}

input[type="radio"] + label {
  cursor: pointer;
}

select {
  outline: none;
  cursor: pointer;
}

div {
  outline: none;
}

.visually-hidden:not(:focus):not(:active),
input[type="checkbox"].visually-hidden,
input[type="radio"].visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
